<template>
  <div>
    <header-top-dashboard
      primary
      :custom-right-content="true"
      :show-back-button="true"
    >
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-padding">
              <v-form>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Manufacturer Details</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      Manufacturer Name</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Manufacturer Name"
                      v-model="manufacturerDetails.name"
                      required
                      :error-messages="
                        getFieldValidationErrors(
                          v$.manufacturerDetails.name.$errors
                        )
                      "
                      @blur="v$.manufacturerDetails.name.$touch"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      Link Owner</label
                    >

                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Manufacturer Owner"
                      :items="manufacturerOwners"
                      :item-text="
                        (row) => {
                          return `${row.firstname} ${row.lastname}`;
                        }
                      "
                      item-value="id"
                      v-model="manufacturerDetails.ownerId"
                      required
                      :error-messages="
                        getFieldValidationErrors(
                          v$.manufacturerDetails.ownerId.$errors
                        )
                      "
                      @blur="v$.manufacturerDetails.ownerId.$touch"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      CIPC Registration Number
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="CIPC Registration Number"
                      v-model="manufacturerDetails.cipcRegistrationNumber"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      VAT Number</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="VAT Number"
                      v-model="manufacturerDetails.vatNumber"
                      required
                      :error-messages="
                        getFieldValidationErrors(
                          v$.manufacturerDetails.vatNumber.$errors
                        )
                      "
                      @blur="v$.manufacturerDetails.vatNumber.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      Type</label
                    >

                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Manufacturer Type"
                      :items="manufacturerTypes"
                      item-text="value"
                      item-value="key"
                      v-model="manufacturerDetails.type"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.manufacturerDetails.type.$errors
                        )
                      "
                      @blur="v$.manufacturerDetails.type.$touch"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Address Details</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      Search Manufacturer Address</label
                    >
                    <vuetify-google-autocomplete
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Start typing to search the manufacturer address..."
                      v-model="manufacturerDetails.addressAutocompleteModel"
                      id="updateManufacturerAddress"
                      v-on:placechanged="getAddressData"
                      country="za"
                    >
                    </vuetify-google-autocomplete>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      Address Line 1</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Address line 1"
                      v-model="manufacturerDetails.address.addressLine1"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.manufacturerDetails.address.addressLine1.$errors
                        )
                      "
                      @blur="v$.manufacturerDetails.address.addressLine1.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      Address Line 2</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Address line 2"
                      v-model="manufacturerDetails.address.addressLine2"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      Address Line 3
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Address line 3"
                      v-model="manufacturerDetails.address.addressLine3"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      Surburb
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Start typing to search the manufacturer address..."
                      v-model="manufacturerDetails.address.surburb"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.manufacturerDetails.address.surburb.$errors
                        )
                      "
                      @blur="v$.manufacturerDetails.address.surburb.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      City
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Start typing to search the manufacturer address..."
                      v-model="manufacturerDetails.address.city"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.manufacturerDetails.address.city.$errors
                        )
                      "
                      @blur="v$.manufacturerDetails.address.city.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      Province
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Start typing to search the manufacturer address..."
                      v-model="manufacturerDetails.address.province"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.manufacturerDetails.address.province.$errors
                        )
                      "
                      @blur="v$.manufacturerDetails.address.province.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      Latitude
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Start typing to search the manufacturer address..."
                      v-model="manufacturerDetails.address.latitude"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      Longitude
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Start typing to search the manufacturer address..."
                      v-model="manufacturerDetails.address.longitude"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      Address Type
                    </label>
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Address Type"
                      :items="addressTypes"
                      item-text="value"
                      item-value="key"
                      v-model="manufacturerDetails.address.type"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.manufacturerDetails.address.type.$errors
                        )
                      "
                      @input="v$.manufacturerDetails.address.type.$touch"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Contact Details</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="2" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      Country Code
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Country code"
                      v-model="manufacturerDetails.contact.countryCode"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.manufacturerDetails.contact.countryCode.$errors
                        )
                      "
                      @blur="v$.manufacturerDetails.contact.countryCode.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      Contact Number</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Contact Number"
                      v-model="manufacturerDetails.contact.number"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.manufacturerDetails.contact.number.$errors
                        )
                      "
                      @blur="v$.manufacturerDetails.contact.number.$touch"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      Contact Number Type
                    </label>
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Contact Number Type"
                      :items="contactTypes"
                      item-text="value"
                      item-value="key"
                      v-model="manufacturerDetails.contact.type"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.manufacturerDetails.contact.type.$errors
                        )
                      "
                      @blur="v$.manufacturerDetails.contact.type.$touch"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Image</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="3" md="3" class="py-0">
                    <v-card class="card-shadow mb-6">
                      <div class="overlay" @click="showFileSelector()">
                        <v-icon color="#ff5722" size="12" class="overlay-icon">
                          ni-palette
                        </v-icon>
                        <v-img height="200" :src="imageUrl"></v-img>
                      </div>

                      <v-card-title>
                        <p
                          style="
                            width: 100%;
                            text-align: center;
                            font-size: medium;
                          "
                        >
                          Image
                        </p>
                      </v-card-title>
                    </v-card>
                  </v-col>

                  <input
                    @change="uploadImage"
                    ref="fileUploadInput"
                    id="fileUploadInput"
                    type="file"
                    hidden
                  />
                </v-row>

                <v-btn
                  @click="submit"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :loading="submitButton.isLoading"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  color="#5e72e4"
                >
                  Update
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";

export default {
  name: "manufacturers-edit",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    HeaderTopDashboard,
  },
  validations() {
    return {
      manufacturerDetails: {
        name: {
          required,
          $autoDirty: true,
        },
        type: {
          required,
        },
        vatNumber: {
          required,
        },
        cipcRegistrationNumber: {
          required,
        },
        ownerId: {
          required,
        },
        address: {
          type: { required },
          addressLine1: { required },
          surburb: { required },
          city: { required },
          province: { required },
        },
        contact: {
          type: { required },
          countryCode: { required },
          number: { required },
        },
      },
    };
  },
  data() {
    return {
      fileToUpload: null,
      fileToUploadDesignation: "",
      addressAutocomplete: true,
      addressTypes: [],
      manufacturerTypes: [],
      contactTypes: [],
      imageUrl: require("@/assets/img/placeholder-200x200.png"),
      manufacturerDetails: {
        id: 0,
        name: "",
        ownerId: null,
        imageUri: "",
        cipcRegistrationNumber: "",
        vatNumber: "",
        type: "",
        addressAutocompleteModel: null,
        address: {
          id: 0,
          type: "",
          longitude: "",
          latitude: "",
          addressLine1: "",
          addressLine2: "",
          addressLine3: "",
          surburb: "",
          city: "",
          province: "",
        },
        contact: {
          id: 0,
          type: "",
          countryCode: "+27",
          number: "",
        },
      },
      manufacturerOwners: [],
      submitButton: {
        isLoading: false,
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    getFieldValidationErrors(errors) {
      const messages = [];

      for (let i = 0; i < errors.length; i++) {
        messages.push(`${errors[i].$message}`);
      }

      return messages;
    },

    getAddressData(addressData, placeResultData) {
      this.setAddressDetailsFromGooglePlacesApi(
        this.manufacturerDetails.address,
        addressData,
        placeResultData
      );
    },

    loadData() {
      this.$http
        .get(
          `/manufacturers/${this.$route.params.id}`,
          this.sessionStore.getHttpConfig
        )
        .then((result) => {
          const manufacturer = result.data.data[0];

          let manufacturerOwnerId = 0;
          if (manufacturer.owners.length > 0) {
            manufacturerOwnerId = manufacturer.owners[0].id;
          }

          let addressDetails = {};
          if (manufacturer.address_details.length > 0) {
            addressDetails = manufacturer.address_details[0];
          }

          let contactDetails = {};
          if (manufacturer.contact_details.length > 0) {
            contactDetails = manufacturer.contact_details[0];
          }

          this.manufacturerDetails.id = manufacturer.id;
          this.manufacturerDetails.name = manufacturer.name;
          this.manufacturerDetails.cipcRegistrationNumber =
            manufacturer.cipc_registration_number;
          this.manufacturerDetails.type = manufacturer.type;
          this.manufacturerDetails.vatNumber = manufacturer.vat_number;
          this.manufacturerDetails.imageUri = manufacturer.image_uri;
          this.manufacturerDetails.ownerId = manufacturerOwnerId;

          this.imageUrl = manufacturer.image_uri;

          this.manufacturerDetails.addressAutocompleteModel =
            addressDetails.address_line_1;
          this.manufacturerDetails.address.id = addressDetails.id;
          this.manufacturerDetails.address.type = addressDetails.type;
          this.manufacturerDetails.address.longitude = addressDetails.longitude;
          this.manufacturerDetails.address.latitude = addressDetails.latitude;
          this.manufacturerDetails.address.addressLine1 =
            addressDetails.address_line_1;
          this.manufacturerDetails.address.addressLine2 =
            addressDetails.address_line_2;
          this.manufacturerDetails.address.addressLine3 =
            addressDetails.address_line_3;
          this.manufacturerDetails.address.surburb = addressDetails.surburb;
          this.manufacturerDetails.address.city = addressDetails.city;
          this.manufacturerDetails.address.province = addressDetails.province;

          this.manufacturerDetails.contact.id = contactDetails.id;
          this.manufacturerDetails.contact.type = contactDetails.type;
          this.manufacturerDetails.contact.countryCode =
            contactDetails.country_code;
          this.manufacturerDetails.contact.number = contactDetails.number;
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while loading the manufacturer details. Please try again later or contact support."
          );
        });
    },

    async submit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      this.submitButton.isLoading = true;

      const manufacturerObject = {
        name: this.manufacturerDetails.name,
        image_uri: this.manufacturerDetails.imageUri,
        vat_number: this.manufacturerDetails.vatNumber,
        type: this.manufacturerDetails.type,
        cipc_registration_number: this.manufacturerDetails
          .cipcRegistrationNumber,
        status: "active",
        users: [],
      };

      if (this.manufacturerDetails.ownerId) {
        manufacturerObject.users.push({
          id: this.manufacturerDetails.ownerId,
          role: "manufacturer_owner",
        });
      }

      const addressObject = {
        type: this.manufacturerDetails.address.type,
        address_line_1: this.manufacturerDetails.address.addressLine1,
        address_line_2: this.manufacturerDetails.address.addressLine2,
        address_line_3: this.manufacturerDetails.address.addressLine3,
        surburb: this.manufacturerDetails.address.surburb,
        city: this.manufacturerDetails.address.city,
        province: this.manufacturerDetails.address.province,
        latitude: this.manufacturerDetails.address.latitude.toString(),
        longitude: this.manufacturerDetails.address.longitude.toString(),
      };

      const contactObject = {
        country_code: this.manufacturerDetails.contact.countryCode,
        number: this.manufacturerDetails.contact.number,
        type: this.manufacturerDetails.contact.type,
      };

      const baseUri = `/manufacturers/${this.manufacturerDetails.id}`;
      this.$http
        .patch(baseUri, manufacturerObject, this.sessionStore.getHttpConfig)
        .then(async () => {
          const promises = [];

          if (addressObject.id != null) {
            promises.push(
              this.$http.patch(
                `${baseUri}/address-details/${this.manufacturerDetails.address.id}`,
                addressObject,
                this.sessionStore.getHttpConfig
              )
            );
          } else {
            promises.push(
              this.$http.post(
                `${baseUri}/address-details`,
                addressObject,
                this.sessionStore.getHttpConfig
              )
            );
          }

          if (addressObject.id != null) {
            promises.push(
              this.$http.patch(
                `${baseUri}/contact-details/${this.manufacturerDetails.contact.id}`,
                contactObject,
                this.sessionStore.getHttpConfig
              )
            );
          } else {
            promises.push(
              this.$http.post(
                `${baseUri}/contact-details`,
                contactObject,
                this.sessionStore.getHttpConfig
              )
            );
          }

          return Promise.all(promises).then((promiseResult) => {
            this.$notify.success("Manufacturer updated successfully!");
          });
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while saving the manufacturer. Please try again later or contact support."
          );
        })
        .finally(() => {
          this.addressAutocomplete = true;
          this.submitButton.isLoading = false;
        });
    },

    showFileSelector() {
      const fileInputElement = this.$refs.fileUploadInput;
      fileInputElement.click();
    },

    uploadImage(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);

      const config = {
        ...this.sessionStore.getHttpConfig,
      };

      config.headers["Content-Type"] = "multipart/form-data";

      this.$http
        .patch(
          `/manufacturers/${this.manufacturerDetails.id}`,
          formData,
          config
        )
        .then(() => {
          this.fileToUpload = null;
          this.loadData();
          this.$notify.success("Image uploaded successfully!");

          this.fileToUploadDesignation = "";
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while uploading the image. Please try again later or contact support."
          );
        });
    },
  },
  async mounted() {
    this.loadData();

    this.fetchUsersByRole("manufacturer_owner")
      .then((result) => {
        this.manufacturerOwners.splice(0);
        for (let i = 0; i < result.data.data.length; i += 1) {
          this.manufacturerOwners.push(result.data.data[i]);
        }
      })
      .catch((error) => {
        this.handleHttpError(
          error,
          "An error occurred while loading manufacturer owners. Please try again later or contact support."
        );
      });

    this.fetchStaticDataByGrouping("addressTypes")
      .then((result) => {
        this.addressTypes.splice(0);

        for (let i = 0; i < result.data.data.length; i += 1) {
          this.addressTypes.push(result.data.data[i]);
        }
      })
      .catch((error) => {
        this.handleHttpError(
          error,
          "An error occurred while loading address types. Please try again later or contact support."
        );
      });

    this.fetchStaticDataByGrouping("manufacturerTypes")
      .then((result) => {
        this.manufacturerTypes.splice(0);

        for (let i = 0; i < result.data.data.length; i += 1) {
          this.manufacturerTypes.push(result.data.data[i]);
        }
      })
      .catch((error) => {
        this.handleHttpError(
          error,
          "An error occurred while loading manufacturer types. Please try again later or contact support."
        );
      });

    this.fetchStaticDataByGrouping("contactTypes")
      .then((result) => {
        this.contactTypes.splice(0);

        for (let i = 0; i < result.data.data.length; i += 1) {
          this.contactTypes.push(result.data.data[i]);
        }
      })
      .catch((error) => {
        this.handleHttpError(
          error,
          "An error occurred while loading contact types. Please try again later or contact support."
        );
      });
  },
};
</script>
