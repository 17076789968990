var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-top-dashboard',{attrs:{"primary":"","custom-right-content":true,"show-back-button":true}}),_c('v-container',{staticClass:"pt-0 px-6 mt-n16",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mt-n3"},[_c('v-card',{staticClass:"card-shadow mb-6"},[_c('v-card-text',{staticClass:"card-padding"},[_c('v-form',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12"}},[_c('h3',[_vm._v("Manufacturer Details")]),_c('v-divider',{staticStyle:{"margin-bottom":"15px"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"label-color font-weight-600 mb-2 d-block",attrs:{"for":""}},[_vm._v(" Manufacturer Name")]),_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light",attrs:{"outlined":"","placeholder":"Manufacturer Name","required":"","error-messages":_vm.getFieldValidationErrors(
                        _vm.v$.manufacturerDetails.name.$errors
                      )},on:{"blur":_vm.v$.manufacturerDetails.name.$touch},model:{value:(_vm.manufacturerDetails.name),callback:function ($$v) {_vm.$set(_vm.manufacturerDetails, "name", $$v)},expression:"manufacturerDetails.name"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"label-color font-weight-600 mb-2 d-block",attrs:{"for":""}},[_vm._v(" Link Owner")]),_c('v-select',{staticClass:"input-style font-size-input text-light-input placeholder-light",attrs:{"outlined":"","placeholder":"Manufacturer Owner","items":_vm.manufacturerOwners,"item-text":function (row) {
                        return ((row.firstname) + " " + (row.lastname));
                      },"item-value":"id","required":"","error-messages":_vm.getFieldValidationErrors(
                        _vm.v$.manufacturerDetails.ownerId.$errors
                      )},on:{"blur":_vm.v$.manufacturerDetails.ownerId.$touch},model:{value:(_vm.manufacturerDetails.ownerId),callback:function ($$v) {_vm.$set(_vm.manufacturerDetails, "ownerId", $$v)},expression:"manufacturerDetails.ownerId"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"label-color font-weight-600 mb-2 d-block",attrs:{"for":""}},[_vm._v(" CIPC Registration Number ")]),_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light",attrs:{"outlined":"","placeholder":"CIPC Registration Number"},model:{value:(_vm.manufacturerDetails.cipcRegistrationNumber),callback:function ($$v) {_vm.$set(_vm.manufacturerDetails, "cipcRegistrationNumber", $$v)},expression:"manufacturerDetails.cipcRegistrationNumber"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"label-color font-weight-600 mb-2 d-block",attrs:{"for":""}},[_vm._v(" VAT Number")]),_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light",attrs:{"outlined":"","placeholder":"VAT Number","required":"","error-messages":_vm.getFieldValidationErrors(
                        _vm.v$.manufacturerDetails.vatNumber.$errors
                      )},on:{"blur":_vm.v$.manufacturerDetails.vatNumber.$touch},model:{value:(_vm.manufacturerDetails.vatNumber),callback:function ($$v) {_vm.$set(_vm.manufacturerDetails, "vatNumber", $$v)},expression:"manufacturerDetails.vatNumber"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"label-color font-weight-600 mb-2 d-block",attrs:{"for":""}},[_vm._v(" Type")]),_c('v-select',{staticClass:"input-style font-size-input text-light-input placeholder-light",attrs:{"outlined":"","placeholder":"Manufacturer Type","items":_vm.manufacturerTypes,"item-text":"value","item-value":"key","error-messages":_vm.getFieldValidationErrors(
                        _vm.v$.manufacturerDetails.type.$errors
                      )},on:{"blur":_vm.v$.manufacturerDetails.type.$touch},model:{value:(_vm.manufacturerDetails.type),callback:function ($$v) {_vm.$set(_vm.manufacturerDetails, "type", $$v)},expression:"manufacturerDetails.type"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12"}},[_c('h3',[_vm._v("Address Details")]),_c('v-divider',{staticStyle:{"margin-bottom":"15px"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12"}},[_c('label',{staticClass:"label-color font-weight-600 mb-2 d-block",attrs:{"for":""}},[_vm._v(" Search Manufacturer Address")]),_c('vuetify-google-autocomplete',{staticClass:"input-style font-size-input text-light-input placeholder-light",attrs:{"outlined":"","placeholder":"Start typing to search the manufacturer address...","id":"updateManufacturerAddress","country":"za"},on:{"placechanged":_vm.getAddressData},model:{value:(_vm.manufacturerDetails.addressAutocompleteModel),callback:function ($$v) {_vm.$set(_vm.manufacturerDetails, "addressAutocompleteModel", $$v)},expression:"manufacturerDetails.addressAutocompleteModel"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('label',{staticClass:"label-color font-weight-600 mb-2 d-block",attrs:{"for":""}},[_vm._v(" Address Line 1")]),_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light",attrs:{"outlined":"","placeholder":"Address line 1","error-messages":_vm.getFieldValidationErrors(
                        _vm.v$.manufacturerDetails.address.addressLine1.$errors
                      )},on:{"blur":_vm.v$.manufacturerDetails.address.addressLine1.$touch},model:{value:(_vm.manufacturerDetails.address.addressLine1),callback:function ($$v) {_vm.$set(_vm.manufacturerDetails.address, "addressLine1", $$v)},expression:"manufacturerDetails.address.addressLine1"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('label',{staticClass:"label-color font-weight-600 mb-2 d-block",attrs:{"for":""}},[_vm._v(" Address Line 2")]),_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light",attrs:{"outlined":"","placeholder":"Address line 2"},model:{value:(_vm.manufacturerDetails.address.addressLine2),callback:function ($$v) {_vm.$set(_vm.manufacturerDetails.address, "addressLine2", $$v)},expression:"manufacturerDetails.address.addressLine2"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('label',{staticClass:"label-color font-weight-600 mb-2 d-block",attrs:{"for":""}},[_vm._v(" Address Line 3 ")]),_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light",attrs:{"outlined":"","placeholder":"Address line 3"},model:{value:(_vm.manufacturerDetails.address.addressLine3),callback:function ($$v) {_vm.$set(_vm.manufacturerDetails.address, "addressLine3", $$v)},expression:"manufacturerDetails.address.addressLine3"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('label',{staticClass:"label-color font-weight-600 mb-2 d-block",attrs:{"for":""}},[_vm._v(" Surburb ")]),_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light",attrs:{"outlined":"","placeholder":"Start typing to search the manufacturer address...","error-messages":_vm.getFieldValidationErrors(
                        _vm.v$.manufacturerDetails.address.surburb.$errors
                      )},on:{"blur":_vm.v$.manufacturerDetails.address.surburb.$touch},model:{value:(_vm.manufacturerDetails.address.surburb),callback:function ($$v) {_vm.$set(_vm.manufacturerDetails.address, "surburb", $$v)},expression:"manufacturerDetails.address.surburb"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('label',{staticClass:"label-color font-weight-600 mb-2 d-block",attrs:{"for":""}},[_vm._v(" City ")]),_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light",attrs:{"outlined":"","placeholder":"Start typing to search the manufacturer address...","error-messages":_vm.getFieldValidationErrors(
                        _vm.v$.manufacturerDetails.address.city.$errors
                      )},on:{"blur":_vm.v$.manufacturerDetails.address.city.$touch},model:{value:(_vm.manufacturerDetails.address.city),callback:function ($$v) {_vm.$set(_vm.manufacturerDetails.address, "city", $$v)},expression:"manufacturerDetails.address.city"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('label',{staticClass:"label-color font-weight-600 mb-2 d-block",attrs:{"for":""}},[_vm._v(" Province ")]),_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light",attrs:{"outlined":"","placeholder":"Start typing to search the manufacturer address...","error-messages":_vm.getFieldValidationErrors(
                        _vm.v$.manufacturerDetails.address.province.$errors
                      )},on:{"blur":_vm.v$.manufacturerDetails.address.province.$touch},model:{value:(_vm.manufacturerDetails.address.province),callback:function ($$v) {_vm.$set(_vm.manufacturerDetails.address, "province", $$v)},expression:"manufacturerDetails.address.province"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('label',{staticClass:"label-color font-weight-600 mb-2 d-block",attrs:{"for":""}},[_vm._v(" Latitude ")]),_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light",attrs:{"outlined":"","placeholder":"Start typing to search the manufacturer address..."},model:{value:(_vm.manufacturerDetails.address.latitude),callback:function ($$v) {_vm.$set(_vm.manufacturerDetails.address, "latitude", $$v)},expression:"manufacturerDetails.address.latitude"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('label',{staticClass:"label-color font-weight-600 mb-2 d-block",attrs:{"for":""}},[_vm._v(" Longitude ")]),_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light",attrs:{"outlined":"","placeholder":"Start typing to search the manufacturer address..."},model:{value:(_vm.manufacturerDetails.address.longitude),callback:function ($$v) {_vm.$set(_vm.manufacturerDetails.address, "longitude", $$v)},expression:"manufacturerDetails.address.longitude"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('label',{staticClass:"label-color font-weight-600 mb-2 d-block",attrs:{"for":""}},[_vm._v(" Address Type ")]),_c('v-select',{staticClass:"input-style font-size-input text-light-input placeholder-light",attrs:{"outlined":"","placeholder":"Address Type","items":_vm.addressTypes,"item-text":"value","item-value":"key","error-messages":_vm.getFieldValidationErrors(
                        _vm.v$.manufacturerDetails.address.type.$errors
                      )},on:{"input":_vm.v$.manufacturerDetails.address.type.$touch},model:{value:(_vm.manufacturerDetails.address.type),callback:function ($$v) {_vm.$set(_vm.manufacturerDetails.address, "type", $$v)},expression:"manufacturerDetails.address.type"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12"}},[_c('h3',[_vm._v("Contact Details")]),_c('v-divider',{staticStyle:{"margin-bottom":"15px"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"2"}},[_c('label',{staticClass:"label-color font-weight-600 mb-2 d-block",attrs:{"for":""}},[_vm._v(" Country Code ")]),_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light",attrs:{"outlined":"","placeholder":"Country code","error-messages":_vm.getFieldValidationErrors(
                        _vm.v$.manufacturerDetails.contact.countryCode.$errors
                      )},on:{"blur":_vm.v$.manufacturerDetails.contact.countryCode.$touch},model:{value:(_vm.manufacturerDetails.contact.countryCode),callback:function ($$v) {_vm.$set(_vm.manufacturerDetails.contact, "countryCode", $$v)},expression:"manufacturerDetails.contact.countryCode"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"label-color font-weight-600 mb-2 d-block",attrs:{"for":""}},[_vm._v(" Contact Number")]),_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light",attrs:{"outlined":"","placeholder":"Contact Number","error-messages":_vm.getFieldValidationErrors(
                        _vm.v$.manufacturerDetails.contact.number.$errors
                      )},on:{"blur":_vm.v$.manufacturerDetails.contact.number.$touch},model:{value:(_vm.manufacturerDetails.contact.number),callback:function ($$v) {_vm.$set(_vm.manufacturerDetails.contact, "number", $$v)},expression:"manufacturerDetails.contact.number"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('label',{staticClass:"label-color font-weight-600 mb-2 d-block",attrs:{"for":""}},[_vm._v(" Contact Number Type ")]),_c('v-select',{staticClass:"input-style font-size-input text-light-input placeholder-light",attrs:{"outlined":"","placeholder":"Contact Number Type","items":_vm.contactTypes,"item-text":"value","item-value":"key","error-messages":_vm.getFieldValidationErrors(
                        _vm.v$.manufacturerDetails.contact.type.$errors
                      )},on:{"blur":_vm.v$.manufacturerDetails.contact.type.$touch},model:{value:(_vm.manufacturerDetails.contact.type),callback:function ($$v) {_vm.$set(_vm.manufacturerDetails.contact, "type", $$v)},expression:"manufacturerDetails.contact.type"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12"}},[_c('h3',[_vm._v("Image")]),_c('v-divider',{staticStyle:{"margin-bottom":"15px"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"3","md":"3"}},[_c('v-card',{staticClass:"card-shadow mb-6"},[_c('div',{staticClass:"overlay",on:{"click":function($event){return _vm.showFileSelector()}}},[_c('v-icon',{staticClass:"overlay-icon",attrs:{"color":"#ff5722","size":"12"}},[_vm._v(" ni-palette ")]),_c('v-img',{attrs:{"height":"200","src":_vm.imageUrl}})],1),_c('v-card-title',[_c('p',{staticStyle:{"width":"100%","text-align":"center","font-size":"medium"}},[_vm._v(" Image ")])])],1)],1),_c('input',{ref:"fileUploadInput",attrs:{"id":"fileUploadInput","type":"file","hidden":""},on:{"change":_vm.uploadImage}})],1),_c('v-btn',{staticClass:"font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2",attrs:{"elevation":"0","ripple":false,"height":"43","loading":_vm.submitButton.isLoading,"color":"#5e72e4"},on:{"click":_vm.submit}},[_vm._v(" Update ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }